import axios from "@/config/httpConfig";

// 组织是否开启商务云
export function getgdprTag(data) {
  return axios.post("gdpr/getgdprTag", data);
}

// 获取不同类型数据信息
export function getCountInfo(data) {
  return axios.post("gdpr/getCountInfo", data);
}

// 开启或关闭设置
export function saveSetting(data) {
  return axios.post("gdpr/saveSetting", data);
}

// GDPR开启状态
export function getgdprInfo(data) {
  return axios.post("gdpr/getgdprInfo", data);
}

// 获取列表表头
export function getHeaderList(data) {
  return axios.post("gdpr/getHeaderList", data);
}

// 获取概览数据列表
export function getoverviewList(data) {
  return axios.post("gdpr/getoverviewList", data);
}

// 保存数据处理基础
export function updateDataBasics(data) {
  return axios.post("gdpr/updateDataBasics", data);
}

// 获取个人字段数据列表
export function getlabelList(data) {
  return axios.post("gdpr/getlabelList", data);
}

// 获取管理个人字段下拉
export function getlabelOption(data) {
  return axios.post("gdpr/getlabelOption", data);
}