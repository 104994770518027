<template>
  <div>
    <div class="tab_Line"></div>
    <div class="gdprSetting">
      <el-tabs v-model="activeName">
        <!-- 占位 -->
        <el-tab-pane label="" name="third"></el-tab-pane>
        <!-- 销售云部分 -->
        <el-tab-pane :label="$t('c279')" name="first">
          <div class="sales_Cloud">
            <!-- 合规性开关 -->
            <div class="sales_switch">
              <span class="switch_span">{{ $t("c276") }}</span>
              <el-switch
                v-model="switchValue"
                active-color="#31C985"
                :inactive-text="inactiveText"
                @change="switchChange"
              >
              </el-switch>
            </div>
            <!-- 切换内容 -->
            <!-- 未开启展示 -->
            <div class="blank_Content" v-if="!switchValue">
              <div class="space_Content">
                <div class="module_Img">
                  <img :src="blankProcess" alt="" />
                  <div class="module_Img_title">{{ $t("c281") }}</div>
                  <div class="module_Img_text">{{ $t("c282") }}</div>
                </div>
                <div class="module_Img">
                  <img :src="blankRequest" alt="" />
                  <div class="module_Img_title">{{ $t("c283") }}</div>
                  <div class="module_Img_text">{{ $t("c284") }}</div>
                </div>
              </div>
            </div>
            <!-- 开启展示 -->
            <div class="main_Content" v-else>
              <el-tabs v-model="activeTabs" @tab-click="tabClick">
                <!-- 概览 -->
                <el-tab-pane :label="$t('c285')" name="firstTab">
                  <!-- 对象选择 -->
                  <div class="main_Content_top">
                    <div class="select_obj">
                      <el-select
                        v-model="overviewObj"
                        :placeholder="$t('label.tabpage.pleaseSelectz')"
                        @change="getCountInfo"
                      >
                        <el-option
                          v-for="item in objOptions"
                          v-show="
                            (item.value === 'lead' && leadBool) ||
                            (item.value === 'contact' && contactBool) ||
                            (item.value === 'vendor' && vendorBool)
                          "
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <!-- 数据信息展示 -->
                    <div class="total_show">
                      <div class="total_show_space total_show_space_top">
                        <span>{{ $t("c286") }}({{ noComplianceTotal }})</span>
                        <div class="relative_top">
                          <el-progress
                            :percentage="noComProportion"
                            :show-text="false"
                          ></el-progress>
                          <div class="lookOver_top" @click="detailShow('0')">
                            {{ $t("label.emailtocloudcc.button.view") }}
                          </div>
                        </div>
                        <span>{{ $t("c287") }}({{ complianceTotal }})</span>
                      </div>
                      <div class="total_show_space total_show_space_bottom">
                        <span>{{ $t("c260") }}({{ reDestory }})</span>
                        <div class="relative_bottom">
                          <el-progress
                            :percentage="reDestoryProportion"
                            :show-text="false"
                          ></el-progress>
                          <div class="lookOver_bottom" @click="detailShow('1')">
                            {{ $t("label.emailtocloudcc.button.view") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 搜索框 -->
                  <div v-if="showTableMain">
                    <div class="search_Posi">
                      <div class="search_Posi_left">
                        {{ tableTitle }}{{ tabelLabel }}
                      </div>
                      <div class="search_Posi_right">
                        <el-input
                          v-model="searchInput"
                          :placeholder="$t('label.quickbooks.searchs')"
                          prefix-icon="el-icon-search"
                          @keyup.enter.native="detailShow(localParams)"
                        ></el-input>
                        <el-button type="primary" @click="openDataDialog">{{
                          $t("c288")
                        }}</el-button>
                      </div>
                    </div>
                    <!-- 概览表格数据 -->
                    <div class="table_Posi_first">
                      <table-panel
                        closeLoadMore
                        class="tablePanel"
                        ref="tablePanels"
                        v-if="showTable"
                        :enableInlineEdit="$store.state.userInfoObj.enableInlineEdit!=='false'"
                        :targetType="targetType"
                        :objId="objId"
                        :showTableHeadTool="false"
                        :isQueryCount="false"
                        :border="true"
                        :table-height="tableHeight"
                        :showScreen="showScreen"
                        :filterField="filterField"
                        :show-index="true"
                        :checked="true"
                        :customHeader="false"
                        :total="totalSize"
                        :page-size-list="pageSize"
                        :current-page="skippageNum"
                        :table-attr="tableAttr"
                        :page-obj="pageObj"
                        :noDataTip="noDataTip"
                        :sortField="sortField"
                        :sortDir="sortDir"
                        :pict-loading="pictLoading"
                        @getViewListData="getViewListData"
                        @changeFilterFiled="changeFilterFiled"
                        @onchange="onchange"
                      ></table-panel>
                    </div>
                  </div>
                </el-tab-pane>
                <!-- 首选项 -->
                <el-tab-pane :label="$t('c289')" name="secondTab">
                  <div class="preference">
                    <div class="preference_label">{{ $t("c290") }}</div>

                    <el-checkbox label="lead" v-model="leadBool">{{
                      leadName
                    }}</el-checkbox>
                    <el-checkbox label="contact" v-model="contactBool">{{
                      contactName
                    }}</el-checkbox>
                    <el-checkbox
                      label="vendor"
                      v-model="vendorBool"
                      v-if="isOnCommerce"
                      >{{ vendorName }}</el-checkbox
                    >
                  </div>
                  <div class="preference_button">
                    <el-button @click="cancelEnableObj">{{
                      $t("label.tabpage.cancel")
                    }}</el-button>
                    <el-button type="primary" @click="saveEnableObj('1')">{{
                      $t("label.chatter.save")
                    }}</el-button>
                  </div>
                </el-tab-pane>
                <!-- 个人字段 -->
                <el-tab-pane :label="$t('c263')" name="thirdTab">
                  <div class="search_Posi">
                    <div class="search_Posi_left">
                      <el-select
                        v-model="personalFieldObj"
                        :placeholder="$t('label.tabpage.pleaseSelectz')"
                        @change="getlabelList"
                      >
                        <el-option
                          v-for="item in objOptions"
                          v-show="
                            (item.value === 'lead' && leadBool) ||
                            (item.value === 'contact' && contactBool) ||
                            (item.value === 'vendor' && vendorBool)
                          "
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div class="search_Posi_right">
                      <el-button type="primary" @click="openLabelDialog">{{
                        $t("c264")
                      }}</el-button>
                    </div>
                  </div>
                  <!-- 个人字段表格数据 -->
                  <div class="table_Posi_second">
                    <el-table
                      :data="tableData"
                      height="386"
                      border
                      style="width: 100%"
                    >
                      <el-table-column
                        v-for="(item, index) in headList"
                        :key="index"
                        :label="item.name"
                        :prop="item.value"
                        min-width="106"
                      >
                        <template slot-scope="scope">
                          <span>{{ scope.row[item.value] }}</span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <!-- 分页 -->
                  <div class="paginationBox">
                    <el-pagination
                      @size-change="handleSizeChangeP"
                      @current-change="handleCurrentChangeP"
                      :current-page="curPageP"
                      :page-sizes="[10, 25, 50, 100, 200]"
                      :page-size="pageSizeActionP"
                      layout=" prev, pager, next, sizes,jumper"
                      :total="totalP"
                    >
                    </el-pagination>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="市场云" name="second" v-if="isOnMarket"
          >市场云</el-tab-pane
        >
      </el-tabs>
    </div>
    <!-- 确认启用弹窗 -->
    <el-dialog
      :title="$t('c293')"
      :visible.sync="enableVisible"
      top="35vh"
      customClass="enableDialog"
      :before-close="cancelEnableObj"
    >
      <div class="checkBox_Posi">
        <el-checkbox label="lead" v-model="leadBool">{{
          leadName
        }}</el-checkbox>
        <el-checkbox label="contact" v-model="contactBool">{{
          contactName
        }}</el-checkbox>
        <el-checkbox label="vendor" v-model="vendorBool" v-if="isOnCommerce">{{
          vendorName
        }}</el-checkbox>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEnableObj">{{
          $t("label.tabpage.cancel")
        }}</el-button>
        <el-button type="primary" @click="saveEnableObj('0')">{{
          $t("label.chatter.save")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 确认关闭启用弹窗 -->
    <el-dialog
      :title="$t('c294')"
      :visible.sync="closeVisible"
      top="35vh"
      customClass="enableDialog"
    >
      <div class="enableDialog_content">
        <span>{{ $t("c295") }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeVisible = false">{{
          $t("label.tabpage.cancel")
        }}</el-button>
        <el-button type="primary" @click="confirmClose">{{
          $t("label.tabpage.ok")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 管理个人字段 -->
    <el-dialog
      :title="$t('c264') + '-' + tabelLabel"
      :visible.sync="updateVisible"
      top="20vh"
      customClass="enableDialog_update"
      :before-close="updateVisibleClick"
    >
      <el-form :model="popForm" ref="popForm" :rules="popForm.popFormRules">
        <div class="table_Posi_third">
          <el-table
            :data="popForm.popFormData"
            style="width: 100%"
            height="386"
          >
            <el-table-column :label="$t('label.fieldtag')" min-width="106">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'popFormData.' + scope.$index + '.spanlabel'"
                  :rules="popForm.popFormRules.spanlabel"
                >
                  <el-select
                    v-model="scope.row.spanlabel"
                    :placeholder="$t('label.tabpage.pleaseSelectz')"
                    @change="spanChange(scope.row)"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.spanname"
                      :label="item.spanlabel"
                      :value="item.spanname"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('label.mr.title.fieldName')"
              min-width="106"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'popFormData.' + scope.$index + '.spanname'"
                  :rules="popForm.popFormRules.spanname"
                >
                  <el-input
                    class="light-blue-theme"
                    v-model="scope.row.spanname"
                    :placeholder="$t('c296')"
                    :readonly="true"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column :label="$t('c291')" min-width="106">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'popFormData.' + scope.$index + '.datatype'"
                  :rules="popForm.popFormRules.datatype"
                >
                  <el-input
                    class="light-blue-theme"
                    v-model="scope.row.datatype"
                    :placeholder="$t('c297')"
                    :readonly="true"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 按钮 -->
            <el-table-column>
              <template slot-scope="scope">
                <div class="blank_div_Posi">
                  <span
                    v-show="popForm.popFormData.length - 1 !== scope.$index"
                    class="blank_div"
                  ></span>
                  <!-- 加 -->
                  <span v-show="popForm.popFormData.length - 1 == scope.$index">
                    <svg class="icon_small" aria-hidden="true" @click="addRow">
                      <use href="#icon-add1"></use>
                    </svg>
                  </span>
                  <span
                    v-show="popForm.popFormData.length == 1"
                    class="blank_div"
                  ></span>
                  <!-- 减 -->
                  <span v-show="popForm.popFormData.length !== 1">
                    <svg
                      class="icon_small"
                      aria-hidden="true"
                      @click="delRow(scope.$index, scope.row)"
                    >
                      <use href="#icon-minus1"></use>
                    </svg>
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateVisibleClick">{{
          $t("label.tabpage.cancel")
        }}</el-button>
        <el-button type="primary" @click="saveUpdate('popForm')">{{
          $t("label.chatter.save")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 更改数据处理基础弹窗 -->
    <updateDataDialog
      ref="updateDataDialogRef"
      :updateDateOpen="updateDateOpen"
      @updateDateClose="updateDateClose"
      @confirmChange="confirmChange"
    />
  </div>
</template>
<script>
import TablePanel from "@/views/List/TablePanel/index.vue";
import * as gdprSetting from "./api.js";
import updateDataDialog from "@/views/systemSettings/components/gdprSetting/components/updateDataDialog.vue";
export default {
  components: {
    TablePanel,
    updateDataDialog,
  },
  data() {
    return {
      blankProcess: require("@/views/systemSettings/components/gdprSetting/img/dataProcess.png"),
      blankRequest: require("@/views/systemSettings/components/gdprSetting/img/dataRequest.png"),
      inactiveText: this.$i18n.t("label.nousing"), // 开关文字 未启用
      isOnCommerce: true, // 商务云开启
      isOnMarket: true, // 市场云开启
      activeName: "first", // 云默认tab
      switchValue: false, // 合规性开关
      enableVisible: false, // 启用弹窗
      closeVisible: false, // 关闭弹窗
      updateVisible: false, // 管理个人字段
      updateDateOpen: false, // 更改数据处理基础弹窗
      leadBool: false, // 潜在客户开启状态，
      contactBool: false, // 联系人启用状态
      vendorBool: false, // 供应商启用状态
      activeTabs: "firstTab", // 销售云启用后默认tab
      leadName: "", // 潜在客户对象名
      contactName: "", // 联系人对象名
      vendorName: "", // 供应商对象名
      objOptions: [
        {
          value: "lead",
          label: "",
        },
        {
          value: "contact",
          label: "",
        },
        {
          value: "vendor",
          label: "",
        },
      ], // 对象切换选择
      overviewObj: "", // 概览选中对象
      personalFieldObj: "", // 个人字段选中对象
      tableTitle: this.$i18n.t("c298"), // 动态表头
      tabelLabel: "", // 动态表头
      noComplianceTotal: 0, // 不合规数据条数
      complianceTotal: 0, // 合规数据条数
      reDestory: 0, // 请求销毁条数
      noComProportion: 0, // 不合规数据占比
      reDestoryProportion: 0, // 请求销毁占比
      showTableMain: false, //是否展示表格部分
      // 表格属性
      showTable: true, // 是否展示
      tableHeight: "300px", //表格高度
      showScreen: false, // 是否显示筛选器
      filterField: null, //按‘字段名’排序字段
      totalSize: 0, // 总数据数
      pageSize: 50, // 每页多少条数据
      skippageNum: 1, // 页码
      tableAttr: [], // 表头信息
      // 表数据
      localParams: "0", // 当前查看的类型
      targetType: "_blank", // 打开详情页方式
      objId: "",
      pageObj: {
        dataList: [],
      },
      sortField: "", // 排序字段
      sortDir: "asc", // 排序方式
      pictLoading: true, // 表格loading
      searchInput: "", // 搜索关键字
      selectData: [], // 选中数据
      // 个人字段表
      headList: [], // 表头
      tableData: [], // 表数据
      curPageP: 1, // 当前页
      pageSizeActionP: 10, // 每页多少条数据
      totalP: 0, // 总条数
      //  管理个人字段
      recordIds: [], // 记录集合
      options: [], // 管理个人字段下拉
      popForm: {
        popFormData: [],
        popFormRules: {
          //为必填项
          spanlabel: [
            {
              required: true,
              message: "",
              trigger: "change",
            },
          ],
          spanname: [
            {
              required: true,
              message: "",
              trigger: "blur",
            },
          ],
          datatype: [
            {
              required: true,
              message: "",
              trigger: "blur",
            },
          ],
        },
      },
    };
  },
  mounted() {
    // 判断云开启
    this.getgdprTag();
    // 获取启用状态
    this.getgdprInfo();
  },
  methods: {
    // 合规性开关
    switchChange(val) {
      // 如果从关闭到打开
      if (val) {
        this.switchValue = false;
        this.inactiveText = this.$i18n.t("label.nousing");
        this.enableVisible = true;
        this.activeTabs = "firstTab";
      }
      // 如果从打开到关闭
      else {
        this.switchValue = true;
        this.inactiveText = this.$i18n.t("label.inusing");
        this.closeVisible = true;
      }
    },
    // 判断云开启
    getgdprTag() {
      gdprSetting.getgdprTag({}).then((res) => {
        if (res.result) {
          this.isOnCommerce = res.data.isOnCommerce;
          this.isOnMarket = res.data.isOnMarket;
        }
      });
    },

    // 获取启用状态
    getgdprInfo() {
      gdprSetting.getgdprInfo({}).then((res) => {
        if (res.result) {
          // 对象名字
          this.leadName = res.data.salesCloud.fieldName.leadName;
          this.contactName = res.data.salesCloud.fieldName.contactName;
          this.vendorName = res.data.salesCloud.fieldName.vendorName;
          // 给下拉赋值
          this.objOptions.forEach((item) => {
            if (item.value == "lead") {
              item.label = this.leadName;
            } else if (item.value == "contact") {
              item.label = this.contactName;
            } else if (item.value == "vendor") {
              item.label = this.vendorName;
            }
          });
          this.leadBool = res.data.salesCloud.lead;
          this.contactBool = res.data.salesCloud.contact;
          this.vendorBool = res.data.salesCloud.vendor;
          if (this.leadBool) {
            this.overviewObj = "lead";
            this.personalFieldObj = "lead";
          } else if (this.contactBool) {
            this.overviewObj = "contact";
            this.personalFieldObj = "contact";
          } else {
            this.overviewObj = "vendor";
            this.personalFieldObj = "vendor";
          }
          if (res.data.salesCloud.isEnable == true) {
            this.switchValue = true;
            this.inactiveText = this.$i18n.t("label.inusing");
            // 获取不同类型数据信息
            this.getCountInfo();
          }
        }
      });
    },

    // 保存启用对象
    saveEnableObj(val) {
      if (this.leadBool || this.contactBool || this.vendorBool) {
        let params = {
          cloudType: "salesCloud",
          isEnable: true,
          lead: this.leadBool,
          contact: this.contactBool,
          vendor: this.vendorBool,
        };
        gdprSetting.saveSetting(params).then((res) => {
          if (res.result) {
            this.switchValue = true;
            this.inactiveText = this.$i18n.t("label.inusing");
            this.enableVisible = false;
            // 重新获取信息
            this.getgdprInfo();
            if (val == "0") {
              this.$message({
                message: this.$i18n.t("label.group.isusing.success"),
                type: "success",
              });
            } else if (val == "1") {
              this.$message({
                message: this.$i18n.t("label.search.saveok"),
                type: "success",
              });
            }
          }
        });
      } else {
        this.$message(this.$i18n.t("c299"));
      }
    },

    // 取消保存开启对象
    cancelEnableObj() {
      gdprSetting.getgdprInfo({}).then((res) => {
        // 重新获取信息
        if (res.result) {
          this.leadBool = res.data.salesCloud.lead;
          this.contactBool = res.data.salesCloud.contact;
          this.vendorBool = res.data.salesCloud.vendor;
          this.enableVisible = false;
          if (this.leadBool) {
            this.overviewObj = "lead";
            this.personalFieldObj = "lead";
          } else if (this.contactBool) {
            this.overviewObj = "contact";
            this.personalFieldObj = "contact";
          } else {
            this.overviewObj = "vendor";
            this.personalFieldObj = "vendor";
          }
        }
      });
    },
    // 确认关闭启用弹窗
    confirmClose() {
      let params = {
        cloudType: "salesCloud",
        isEnable: "false",
        lead: false,
        contact: false,
        vendor: false,
      };
      gdprSetting.saveSetting(params).then((res) => {
        if (res.result) {
          this.$message({
            message: this.$i18n.t("mobel_web_closed"),
            type: "success",
          });
          this.switchValue = false;
          this.inactiveText = this.$i18n.t("label.nousing");
          this.closeVisible = false;
          this.leadBool = false;
          this.contactBool = false;
          this.vendorBool = false;
        }
      });
    },

    // 获取概览下不同类型数据信息
    getCountInfo() {
      let params = {
        listType: this.overviewObj,
      };
      gdprSetting.getCountInfo(params).then((res) => {
        if (res.result) {
          // 条数
          this.noComplianceTotal = res.data.noComplianceList.count;
          this.complianceTotal = res.data.complianceList.count;
          this.reDestory = res.data.reDestory.count;
          // 占比
          this.noComProportion = res.data.noComplianceList.proportion * 100;
          this.reDestoryProportion = res.data.reDestory.proportion * 100;

          // 切换对象后刷新不合规和请求销毁数据,隐藏下方列表
          this.showTableMain = false;
        }
      });
    },

    // 展示概览表格数据
    detailShow(val) {
      this.localParams = val;
      if (val == "0") {
        this.tableTitle = this.$i18n.t("c298");
      } else if (val == "1") {
        this.tableTitle = this.$i18n.t("c300");
      }
      this.getLabel(this.overviewObj);
      this.showTableMain = true;

      this.sendObjId();
      let headerParams = {
        listType: this.overviewObj,
      };
      gdprSetting.getHeaderList(headerParams).then((res) => {
        this.tableAttr = res.data;
        this.tableAttr.forEach((item, idx) => {
          item.sortAsc = false;
          item.sortDesc = false;
          item.filterScreen = false;
          item.popWidth = (item.nameLabel.length + 6) * 15;
          // 名称、查找、主详、查找多选均可跳转
          // 事件及任务下的主题、名称、相关项可跳转
          if (
            item.schemefieldName === "name" ||
            item.schemefieldType === "Y" ||
            item.schemefieldType === "M" ||
            item.schemefieldType === "MR" ||
            item.schemefieldName === "subject" ||
            item.lookupObj === "user" ||
            item.schemefieldName === "whoid" ||
            item.schemefieldName === "relateid"
          ) {
            this.$set(this.tableAttr[idx], "click", "detail");
          }

          // 项目管理-实际工作清单点击日期跳转详情
          if (this.$cookies.get("activeTabObjId") === "projectworklist") {
            if (item.schemefieldType === "D") {
              this.$set(this.tableAttr[idx], "click", "detail");
            }
          }
          this.$set(this.tableAttr[idx], "sortByThis", false);
          this.$set(this.tableAttr[idx], "sortDir", "asc");
          this.$set(this.tableAttr[idx], "fixed", false);
          this.$set(this.tableAttr[idx], "locked", false);
        });
      });
      this.getViewListData();
    },
    // 获取概览表格数据
    getViewListData(val) {
      // 标识子组件分页传参
      let flag = Object.prototype.toString.call(val) === "[object Object]";
      if (flag) {
        // 每页条数
        this.pageSize = val.pageSize ? val.pageSize : this.pageSize;
        // 当前页数
        this.skippageNum = val.curPage ? val.curPage : this.skippageNum;
      }
      this.pictLoading = true;
      let dataParams = {
        listType: this.overviewObj,
        pageNUM: this.skippageNum,
        pageSize: this.pageSize,
        dataType: this.localParams,
        keyword: this.searchInput,
      };
      gdprSetting.getoverviewList(dataParams).then((res) => {
        this.pageObj.dataList = res.data.tableList;
        // 总记录数
        this.totalSize = res.data.totalCount;
        this.pictLoading = false;
        this.$nextTick(() => {
          this.$refs.tablePanels.$refs.tablePanel.bodyWrapper.scrollTop = 0;
        });
      });
    },

    // 获取当前label
    getLabel(val) {
      let obj = {};
      obj = this.objOptions.find((item) => {
        return item.value === val;
      });
      this.tabelLabel = obj.label;
    },

    // 传objId
    
    sendObjId() {
      if (this.overviewObj == "vendor") {
        this.objId = "20211217cpqvendor001";
      } else {
        this.objId = this.overviewObj;
      }
    },

    // 切换tab
    tabClick() {
      if (this.activeTabs == "thirdTab") {
        this.getlabelList();
      }
    },

    // 获取个人字段表格数据
    getlabelList() {
      let params = {
        listType: this.personalFieldObj,
        pageNum: this.curPageP,
        pageSize: this.pageSizeActionP,
      };
      gdprSetting.getlabelList(params).then((res) => {
        if (res.result) {
          this.headList = res.data.headLabel;
          this.tableData = res.data.personalFieldLists;
          this.totalP = res.data.totalCount;
        }
      });
    },

    // 分页
    handleSizeChangeP(val) {
      // 每页多少条数据
      this.pageSizeActionP = val ? val : this.pageSizeActionP;
      this.getlabelList();
    },

    // 分页
    handleCurrentChangeP(val) {
      // 当前页数
      this.curPageP = val ? val : this.curPageP;
      this.getlabelList();
    },

    // 打开管理个人字段
    openLabelDialog() {
      this.updateVisible = true;
      // 获取弹框title
      this.getLabel(this.personalFieldObj);
      if (this.tableData.length == 0) {
        this.popForm.popFormData.push({
          spanlabel: "",
          spanname: "",
          datatype: "",
        });
      } else {
        this.tableData.forEach((item) => {
          this.popForm.popFormData.push({
            spanlabel: item.tagname,
            spanname: item.fieldname,
            datatype: item.datatype,
          });
        });
      }
      this.spanChangeSelect();
    },

    // 关闭管理个人字段
    updateVisibleClick() {
      this.popForm.popFormData = [];
      this.updateVisible = false;
    },

    // 获取管理个人字段下拉
    spanChangeSelect() {
      this.popForm.popFormData.forEach((item) => {
        this.recordIds.push(item.spanname);
      });
      let params = {
        objId: this.personalFieldObj,
        selectLabel: this.recordIds.join(",") || "",
      };
      gdprSetting.getlabelOption(params).then((res) => {
        if (res.result) {
          this.options = res.data.setupFieldLists;
          this.recordIds = [];
        }
      });
    },

    // 切换字段标签
    spanChange(row) {
      let obj = {};
      obj = this.options.find((item) => {
        return item.spanname == row.spanlabel;
      });
      row.spanlabel = obj.spanlabel;
      row.spanname = obj.spanname;
      row.datatype = this.$i18n.t("c363");
      this.spanChangeSelect();
    },

    // 保存管理个人字段
    saveUpdate(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            listType: this.personalFieldObj,
            fieldsRoles: JSON.stringify(this.popForm.popFormData),
          };
          gdprSetting.getlabelList(params).then((res) => {
            if (res.result) {
              this.popForm.popFormData = [];
              this.updateVisible = false;
              this.getlabelList();
            }
          });
        } else {
          return false;
        }
      });
    },

    // 增加一行
    addRow() {
      this.popForm.popFormData.push({
        spanlabel: "",
        spanname: "",
        datatype: "",
      });
    },

    // 删除一行
    delRow(index) {
      this.popForm.popFormData.splice(index, 1);
    },
    // 表格选中数据
    onchange(val) {
      val.map((item) => {
        this.selectData.push(item.id);
      });
    },
    // 打开更改数据处理基础
    openDataDialog() {
      if (this.$refs.tablePanels.checkedList.length < 1) {
        this.$message(this.$i18n.t("c308"));
      } else {
        this.updateDateOpen = true;
      }
    },
    // 关闭更改数据处理基础
    updateDateClose() {
      this.updateDateOpen = false;
    },
    // 确认更改数据处理基础
    confirmChange(val) {
      if (this.selectData.length == 0) {
        this.$message(this.$i18n.t("c308"));
      } else {
        let params = {
          recordId: this.selectData.join(",") || "",
          dataHandle: val.region,
          remarks: val.textarea,
        };
        gdprSetting.updateDataBasics(params).then((res) => {
          if (res.result) {
            this.getCountInfo();
            this.getViewListData();
            this.$refs.updateDataDialogRef.updateDateCloseS();
            this.$refs.tablePanels.cancelAllchecked();
            this.$message({
              message: this.$i18n.t("vue_label_notice_change_success"),
              type: "success",
            });
          }
        });
      }
    },

    // 表格方法
    // 修改按‘字段名’排序字段
    changeFilterFiled(field) {
      this.filterField = field;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .gdprSetting {
  .el-tabs__item {
    font-size: 18px;
    color: #333333;
  }
  .el-tabs__item.is-active {
    font-size: 18px;
    font-weight: bold;
    color: #2d6cfc;
  }
  .el-tabs__nav-wrap::after {
    height: 1px;
  }
}
::v-deep .sales_Cloud {
  .sales_switch {
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    .switch_span {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      margin-right: 30px;
    }
    .el-switch__label.is-active {
      font-size: 14px;
      color: #afafaf;
    }
    .el-switch__label {
      font-size: 14px;
      color: #31c985;
    }
  }
}
.blank_Content {
  min-height: calc(100vh - 366px);
  display: grid;
  place-items: center;
  .space_Content {
    width: 870px;
    display: flex;
    justify-content: space-between;
    .module_Img {
      text-align: center;
      .module_Img_title {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        margin: 20px 0 16px;
      }
      .module_Img_text {
        font-size: 18px;
        color: #333333;
      }
    }
  }
}
::v-deep .enableDialog {
  width: 420px;
  .checkBox_Posi {
    margin-left: 10px;
    font-size: 14px;
    color: #333333;
    .el-checkbox__inner {
      width: 16px;
      height: 16px;
    }
    .el-checkbox__inner::after {
      left: 5px;
      top: 2px;
    }
  }
  .el-button {
    height: 30px;
    font-size: 14px;
  }
  .enableDialog_content {
    font-size: 14px;
    color: #080707;
  }
}

::v-deep .enableDialog_update {
  width: 1000px;
}

::v-deep .main_Content {
  margin-top: 25px;
  .main_Content_top {
    padding: 0 20px;
  }
  .el-tabs__nav {
    border-radius: 20px;
    border: 1px solid rgba(45, 108, 252, 0.2);
    padding: 4px 4px 3px 4px;
    margin-left: 20px;
  }
  .el-tabs__nav-wrap::after {
    position: static !important;
  }
  .el-tabs__active-bar {
    height: 0;
  }
  .el-tabs__item {
    min-width: 96px;
    padding: 0;
    text-align: center;
  }
  .el-tabs__item.is-active {
    background: rgba(45, 108, 252, 0.1);
    border-radius: 16px;
    border: 1px solid rgba(45, 108, 252, 0.6);
  }
  .select_obj {
    margin-top: 12px;
    .el-input__inner {
      height: 30px;
    }
    .el-input__icon {
      line-height: 30px;
    }
  }
  .total_show {
    margin: 40px 0 0 10px;
    .total_show_space {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 40px;
      span {
        min-width: 100px;
      }
      .el-progress-bar {
        width: 500px;
        margin: 0 30px;
      }
    }
    .total_show_space_top {
      .el-progress-bar__outer {
        height: 8px !important;
        background: linear-gradient(270deg, #80deb4 0%, #00bc68 100%);
        border-radius: 4px;
      }
      .el-progress-bar__inner {
        background: linear-gradient(90deg, #e4e6ec 0%, #c5cde0 100%);
        border-radius: 4px;
      }
      .relative_top {
        position: relative;
        .lookOver_top {
          font-size: 14px;
          cursor: pointer;
          color: #2d6cfc;
          position: absolute;
          left: 30px;
          top: 20px;
        }
      }
    }
    .total_show_space_bottom {
      .el-progress-bar__outer {
        height: 8px !important;
        background: #f4f4f4;
        border-radius: 3px;
      }
      .el-progress-bar__inner {
        background: linear-gradient(90deg, #f88d90 0%, #f11b21 100%);
        border-radius: 4px;
      }
      .relative_bottom {
        position: relative;
        .lookOver_bottom {
          cursor: pointer;
          font-size: 14px;
          color: #2d6cfc;
          position: absolute;
          left: 30px;
          top: 20px;
        }
      }
    }
  }
  .search_Posi {
    height: 60px;
    border: 1px #e1e1e1 solid;
    border-left: none;
    border-right: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .search_Posi_left {
      font-size: 14px;
      font-weight: bold;
      color: #080707;
      margin-left: 20px;
    }
    .search_Posi_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-button {
        height: 32px;
        line-height: 0;
        margin-right: 32px;
        margin-left: 10px;
      }
    }
  }
  .preference {
    display: flex;
    align-items: center;
    padding: 40px 64px;
    .preference_label {
      font-size: 14px;
      color: #333333;
      margin-right: 23px;
    }
  }
  .preference_button {
    margin: 80px 0 0 160px;
    .el-button {
      margin-right: 50px;
      height: 30px;
      line-height: 0;
    }
  }
  .table_Posi_second {
    .el-table--border th.el-table__cell {
      background: #fafaf9;
    }
    .el-table .cell {
      margin-left: 15px;
    }
  }
}
::v-deep .table_Posi_third {
  padding: 0 20px;
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background: none;
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  .el-table::before {
    height: 0;
  }
  .blank_div_Posi {
    display: flex;
    justify-content: flex-start;
    .blank_div {
      width: 50px;
      height: 40px;
      margin-right: 10px;
      display: inline-block;
    }
  }
  .icon_small {
    width: 20px;
    height: 20px;
    margin-left: 40px;
    cursor: pointer;
  }
  .el-form-item {
    margin-bottom: 0px;
  }
  .light-blue-theme .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px;
  }
}
.paginationBox {
  display: flex;
  justify-content: end;
}
</style>