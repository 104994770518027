<template>
  <div>
    <el-dialog
      :title="$t('c288')"
      top="35vh"
      :visible.sync="updateDateOpenProp"
      customClass="updateDialog"
      :before-close="updateDateCloseS"
    >
      <!-- 表单数据 -->
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        hide-required-asterisk
        :label-position="labelPosition"
      >
        <!-- 选项列表 -->
        <el-form-item :label="$t('c259')" prop="region">
          <el-select
            v-model="form.region"
            :placeholder="$t('label.tabpage.pleaseSelectz')"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 文本域 -->
        <el-form-item :label="$t('label.description')" prop="textarea">
          <el-input
            type="textarea"
            :rows="4"
            :placeholder="$t('c301')"
            v-model="form.textarea"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateDateClose('form')">{{
          $t("label.tabpage.cancel")
        }}</el-button>
        <el-button type="primary" @click="confirmChange('form')">{{
          $t("label.tabpage.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    updateDateOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      updateDateOpenProp: this.updateDateOpen,
      labelPosition: "left", // 字段对齐方式
      //   表单数据
      form: {
        region: "1",
        textarea: "",
      },
      rules: {
        region: [{ required: true, message: " ", trigger: "change" }],
      },
      // 下拉列表
      options: [
        {
          value: "1",
          label: this.$i18n.t("label.tabpage.nothingz"),
        },
        {
          value: "2",
          label: this.$i18n.t("c302"),
        },
        {
          value: "3",
          label: this.$i18n.t("vue_label_normal_contract_sf"),
        },
        {
          value: "4",
          label: this.$i18n.t("c303"),
        },
        {
          value: "5",
          label: this.$i18n.t("c304"),
        },
        {
          value: "6",
          label: this.$i18n.t("c305"),
        },
        {
          value: "7",
          label: this.$i18n.t("c306"),
        },
      ],
    };
  },
  methods: {
    // 关闭弹窗
    updateDateClose(formName) {
      this.$refs[formName].resetFields();
      this.$emit("updateDateClose");
    },

    // 确认更改
    confirmChange(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("confirmChange", this.form);
        } else {
          return false;
        }
      });
    },

    updateDateCloseS() {
      this.updateDateClose("form");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .updateDialog {
  width: 472px;
  .el-select {
    width: 100%;
  }
}
</style>